import React from "react";

const Message = () => {
  return (
    <h1
      style={{
        display: "flex",
        height: "90vh",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 10px 0 10px",
      }}
    >
      This Feature is Comming Soon...
    </h1>
  );
};

export default Message;
